import { GetStaticProps } from "next";
import Error from "next/error";
import React from "react";
import { AVAILABLE_LOCALES } from "~/core/locale";
import { serverSideTranslations } from "~/translations/serverSideTranslations";
import { ServerSideGeneratedI18nNamespace } from "~/translations/types";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Custom404PageProps extends ServerSideGeneratedI18nNamespace {}

export const getStaticProps: GetStaticProps<Custom404PageProps> = async (
  context,
) => {
  const locale = (context.locale ?? context.defaultLocale) as AVAILABLE_LOCALES;

  return {
    props: {
      ...(await serverSideTranslations(locale)),
    },
  };
};

export default function Custom404() {
  return <Error statusCode={404} />;
}
